export const errorCode = {
    "-1": "回应超时",
    "312014": "设备升级中，请勿重复操作",
    "312015": "升级失败，飞行器增强图传开启后无法升级，请关闭飞行器增强图传并重启设备后重试",
    "312016": "升级失败，机场和飞行器图传链路异常，请重启机场和飞行器后重试",
    "312022": "飞行器开机失败或未连接，请检查飞行器是否在舱内，是否安装电池，机场和飞行器是否已对频",
    "312023": "推杆闭合失败无法升级飞行器，请检查急停按钮是否被按下，推杆是否有异物卡住",
    "312027": "升级失败，机场未检测到飞行器",
    "312028": "升级失败，设备升级过程中设备被重启",
    "312029": "设备重启中无法进行设备升级，请等待设备重启完成后重试",
    "312030": "升级失败，飞行器增强图传开启后无法升级，请关闭飞行器增强图传后重试",
    "312704": "飞行器电量过低，请充电至20%以上后重试",
    "314000": "设备当前无法支持该操作，建议检查设备当前工作状态",
    "314001": "飞行任务下发失败，请稍后重试",
    "314002": "飞行任务下发失败，请稍后重试",
    "314003": "航线文件格式不兼容，请检查航线文件是否正确",
    "314004": "飞行任务下发失败，请稍后重试或重启机场后重试",
    "314005": "飞行任务下发失败，请稍后重试或重启机场后重试",
    "314006": "飞行器初始化失败，请重启机场后重试",
    "314007": "机场传输航线至飞行器失败，请重启机场后重试",
    "314008": "飞行器起飞前准备超时，请重启机场后重试",
    "314009": "飞行器初始化失败，请重启机场后重试",
    "314010": "航线执行失败，请重启机场后重试",
    "314011": "机场系统异常，无法获取飞行任务执行结果",
    "314012": "飞行器起飞前准备失败，无法执行飞行任务，请重启机场后重试",
    "314013": "飞行任务下发失败，机场无法获取到本次飞行任务的航线，无法执行飞行任务，请稍后重试",
    "314014": "机场系统异常，飞行任务执行失败，请稍后重试",
    "314015": "机场传输精准复拍航线至飞行器失败，无法执行飞行任务，请稍后重试或重启机场后重试",
    "314016": "航线文件解析失败，无法执行飞行任务，请检查航线文件",
    "314017": "机场系统异常，飞行任务执行失败，请稍后重试",
    "314018": "飞行器 RTK 定位异常，无法执行飞行任务，请稍后重试或重启机场后重试",
    "314019": "飞行器 RTK 收敛失败，无法执行飞行任务，请稍后重试或重启机场后重试",
    "314020": "飞行器不在停机坪正中间或飞行器朝向不正确，无法执行飞行任务，请检查飞行器位置和朝向",
    "314021": "飞行器 RTK 定位异常，无法执行飞行任务，请稍后重试或重启机场后重试",
    "314200": "网络断开,飞机自动返航",
    "315000": "机场通信异常，请重启机场后重试",
    "315001": "机场通信异常，请远程开启飞机并等待 1min 后，再次下发任务重试",
    "315002": "机场通信异常，请重启机场后重试",
    "315003": "机场通信异常，请重启机场后重试",
    "315004": "任务失败，请等待两个机场都空闲后，再次下发任务重试",
    "315005": "机场通信异常，请重启机场后重试",
    "315006": "机场通信异常，请重启机场后重试",
    "315007": "机场通信异常，请将机场升级到最新版本或重启机场后重试",
    "315008": "降落机场和起飞机场标定信息不一致，请确认两个机场均链路通畅且使用了相同的网络信息标定",
    "315009": "机场通信异常，请重启机场后重试",
    "315010": "无法停止飞行任务，请稍后重试，如果仍报错请联系大疆售后",
    "315011": "无法停止飞行任务，请稍后重试，如果仍报错请联系大疆售后",
    "315012": "无法停止飞行任务，请稍后重试，如果仍报错请联系大疆售后",
    "315013": "飞行任务下发失败，请稍后重试，如果仍报错请联系大疆售后",
    "315014": "当前任务类型不支持设置返航点",
    "315015": "返航点设置失败，请稍后重试，如果仍报错请联系大疆售后",
    "315016": "飞行任务下发失败，请稍后重试，如果仍报错请联系大疆售后",
    "315017": "飞行任务下发失败，请稍后重试，如果仍报错请联系大疆售后",
    "315018": "任务失败，请等待两个机场都空闲后，再次下发任务重试",
    "315050": "机场系统异常，请重启机场后重试",
    "315051": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315052": "机场位置未收敛，请等待一段时间后重试",
    "315053": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315054": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315055": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315056": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315057": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315058": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315059": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315060": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315061": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315062": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315063": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315064": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "315065": "任务失败，请重启机场并再次下发任务后重试，如果仍报错请联系大疆售后",
    "316001": "飞行器参数配置失败，请重启机场后重试",
    "316002": "飞行器参数配置失败，请重启机场后重试",
    "316003": "飞行器参数配置失败，请重启机场后重试",
    "316004": "飞行器参数配置失败，请重启机场后重试",
    "316005": "飞行器 RTK 收敛失败，无法执行飞行任务，请重启机场后重试",
    "316006": "任务超时，飞行器已丢失或降落时机场未开启舱盖或展开推杆，飞行器无法降落回机场，请尽快至机场部署现场检查飞行器状况",
    "316007": "飞行器初始化失败，请重启机场后重试",
    "316008": "机场获取飞行器控制权失败，无法执行飞行任务，请确认遥控器未锁定控制权",
    "316009": "飞行器电量低于30%，无法执行飞行任务，请充电后重试（建议电量≥50%）",
    "316010": "机场未检测到飞行器，无法执行飞行任务，请检查舱内是否有飞行器，机场与飞行器是否已对频，或重启机场后重试",
    "316011": "飞行器降落位置偏移过大，请检查飞行器是否需要现场摆正",
    "316012": "飞行器起飞前准备失败，无法执行飞行任务，请重启机场后重试",
    "316013": "飞行器起飞前准备失败，无法执行飞行任务，请重启机场后重试",
    "316014": "飞行器起飞前准备失败，无法执行飞行任务，请重启机场后重试",
    "316015": "飞行器 RTK 收敛位置距离机场过远，无法执行飞行任务，请重启机场后重试",
    "316016": "飞行器降落至机场超时，可能是机场与飞行器断连导致，请通过直播查看飞行器是否降落至舱内",
    "316017": "获取飞行器媒体数量超时，可能是机场与飞行器断连导致，请通过直播查看飞行器是否降落至舱内",
    "316018": "飞行任务执行超时，可能是机场与飞行器断连导致，请通过直播查看飞行器是否降落至舱内",
    "316019": "机场系统错误，无法执行飞行任务，请稍后重试",
    "316020": "飞行器使用的 RTK 信号源错误，请稍后重试",
    "316021": "飞行器 RTK 信号源检查超时，请稍后重试",
    "316022": "飞行器无法执行返航指令，请检查飞行器是否已开机，机场与飞行器是否已断连，请确认无以上问题后重试",
    "316023": "飞行器无法执行返航指令，飞行器已被 B 控接管，请在 B 控操控飞行器，或关闭 B 控后重试",
    "316024": "飞行器执行返航指令失败，请检查飞行器是否已起飞，确认飞行器已起飞后请重试",
    "316025": "飞行器参数配置失败，请稍后重试或重启机场后重试",
    "316026": "机场获取飞行器控制权失败，无法执行飞行任务，请确认遥控器未锁定控制权",
    "316027": "飞行器参数配置超时，请稍后重试或重启机场后重试",
    "316029": "机场急停按钮被按下，飞行器将飞往备降点降落，请立即检查飞行器是否已安全降落并将飞行器放回至机场",
    "316032": "获取电池数据超时，请稍后重试或重启飞行器后重试",
    "316033": "飞行器电池循环次数过高，为保证飞行安全，已自动终止任务，建议更换该电池",
    "316034": "无法起飞，飞行器固件版本与机场固件版本不匹配，为保证飞行安全请升级固件后再试",
    "316050": "飞行器因电量过低在舱外降落，请立即检查飞行器是否已安全降落并将飞行器放回至机场",
    "316051": "飞行任务异常，飞行器在舱外降落，请立即检查飞行器是否已安全降落并将飞行器放回至机场",
    "316052": "飞行任务异常，飞行器将飞往备降点降落，请立即检查飞行器是否已安全降落并将飞行器放回至机场",
    "316053": "用户已操控飞行器降落，请立即检查飞行器是否已安全降落并将飞行器放回至机场",
    "316100": "获取相机概要信息失败，请重试",
    "316101": "设置相机为单拍模式失败，请重试",
    "316102": "关闭相机水印失败，请重试",
    "316103": "设置测光模式到平均测光失败，请重试",
    "316104": "切换镜头到广角镜头失败，请重试",
    "316105": "设置相机存储照片失败，请重试",
    "316106": "红外变焦倍数设置失败，请重试",
    "316107": "照片尺寸设置4k失败，请重试",
    "316108": "设置照片存储格式为jpeg格式失败，请重试",
    "316109": "关闭相机畸变矫正失败，请重试",
    "316110": "打开相机机械快门失败，请重试",
    "316111": "设置对焦模式失败，请重试",
    "317001": "获取飞行器媒体文件数量失败，请重启机场后重试",
    "317002": "飞行器存储格式化失败，飞行器未开机、未连接或未检测到相机，请确认无以上问题后重试，或重启飞行器后重试",
    "317003": "飞行器存储格式化失败，请重启飞行器后重试",
    "317004": "机场媒体文件格式化失败，请稍后重试或重启机场后重试",
    "317005": "飞行器结束录像失败，本次飞行任务的媒体文件可能无法上传",
    "317006": "无法格式化，请等待当前飞行器媒体文件下载完成后再试",
    "319001": "机场作业中或设备异常反馈上传日志中，无法执行飞行任务，请等待当前飞行任务或操作执行完成后重试",
    "319002": "机场系统运行异常，请重启机场后重试",
    "319003": "机场系统运行异常，请重新下发任务",
    "319004": "飞行任务执行超时，已自动终止本次飞行任务",
    "319005": "云端与机场通信异常，无法执行飞行任务",
    "319006": "取消飞行任务失败，飞行任务已经在执行中",
    "319007": "修改飞行任务失败，飞行任务已经在执行中",
    "319008": "机场时间与云端时间不同步，机场无法执行飞行任务",
    "319009": "飞行任务下发失败，请稍后重试或重启机场后重试",
    "319010": "机场固件版本过低，无法执行飞行任务，请升级机场固件为最新版本后重试",
    "319015": "设备当前无法支持该操作，建议检查设备当前工作状态",
    "319016": "机场正在执行其他飞行任务，无法执行本次飞行任务",
    "319017": "机场正在处理上次飞行任务媒体文件，无法执行本次飞行任务，请稍后重试",
    "319018": "机场正在自动导出日志中（设备异常反馈），无法执行飞行任务，请稍后重试",
    "319019": "机场正在拉取日志中（设备异常反馈），无法执行飞行任务，请稍后重试",
    "319020": "航线中断失败，请稍后重试",
    "319021": "退出远程控制失败，请稍后重试",
    "319022": "指点飞行失败，请稍后重试",
    "319023": "指点飞行停止失败，请稍后重试",
    "319024": "一键起飞失败，请稍后重试",
    "319025": "机场未准备完成，无法执行云端下发的飞行任务，请稍后重试",
    "319026": "飞行器电池电量低于用户设置的任务开始执行的电量，请等待充电完成后再执行飞行任务",
    "319027": "机场或飞行器剩余存储容量过低，无法执行飞行任务，请等待媒体文件上传，机场和飞行器存储容量释放后再执行飞行任务",
    "319028": "正在更新自定义飞行区",
    "319029": "正在更新离线地图",
    "319030": "操作失败，无飞行器控制权",
    "319031": "控制权异常，请刷新重试",
    "319032": "指点飞行失败，请稍后重试",
    "319033": "虚拟摇杆操作失败，请稍后重试",
    "319034": "虚拟摇杆操作失败，请稍后重试",
    "319035": "急停失败，请稍后重试",
    "319036": "设备远程调试中，请稍后重试",
    "319037": "设备本地调试中，请稍后重试",
    "319038": "设备正在升级，请稍后重试",
    "319042": "航线恢复失败，请稍后重试",
    "319043": "取消返航失败，请稍后重试",
    "319044": "航线任务已结束，无法恢复",
    "319045": "急停成功，请重新按键操作",
    "319046": "无法暂停航线，飞行器尚未进入航线或已退出航线",
    "319999": "机场系统运行异常，请重启机场后重试",
    "321000": "航线执行异常，请稍后重试或重启机场后重试",
    "321004": "航线文件解析失败，无法执行飞行任务，请检查航线文件",
    "321257": "飞行任务已在执行中，请勿重复执行",
    "321258": "飞行任务无法终止，请检查飞行器状态",
    "321259": "飞行任务未开始执行，无法终止飞行任务",
    "321260": "飞行任务未开始执行，无法中断飞行任务",
    "321513": "航线规划高度已超过飞行器限高，机场无法执行飞行任务",
    "321514": "任务失败，起点或终点位于限远区域的缓冲区内或超过了限远距离",
    "321515": "航线穿过限飞区，机场无法执行飞行任务",
    "321516": "飞行器飞行高度过低，飞行任务执行被终止",
    "321517": "飞行器触发避障，飞行任务执行被终止。为保证飞行安全，请勿用当前航线执行断点续飞任务",
    "321519": "飞行器接近限飞区或限远距离自动返航，无法完成航线飞行",
    "321524": "飞行器起飞前准备失败，可能是飞行器无发定位或档位错误导致，请检查飞行器状态",
    "321528": "触碰自定义飞行区边界，航线任务已暂停",
    "321769": "飞行器卫星定位信号差，无法执行飞行任务，请重启机场后重试",
    "321770": "飞行器挡位错误，无法执行飞行任务，请重启机场后重试",
    "321771": "飞行器返航点未设置，无法执行飞行任务，请重启机场后重试",
    "321772": "飞行器电量低于30%，无法执行飞行任务，请充电后重试（建议电量≥50%）",
    "321773": "飞行器执行飞行任务过程中低电量返航，无法完成航线飞行",
    "321775": "飞行器航线飞行过程中失联，无法完成航线飞行",
    "321776": "飞行器 RTK 收敛失败，无法执行飞行任务，请重启机场后重试",
    "321777": "飞行器未悬停，无法开始执行飞行任务",
    "321778": "用户使用 B 控操控飞行器起桨，机场无法执行飞行任务",
    "321784": "任务过程中遇到大风紧急返航",
    "322282": "机场执行飞行任务过程中被中断，飞行器被云端用户或遥控器接管",
    "322283": "机场执行飞行任务过程中被用户触发返航，无法完成航线飞行",
    "322539": "航线的断点信息错误，机场无法执行飞行任务",
    "324012": "日志压缩过程超时，所选日志过多，请减少选择的日志后重试",
    "324013": "设备日志列表获取失败，请稍后重试",
    "324014": "设备日志列表为空，请刷新页面或重启机场后重试",
    "324015": "飞行器已关机或未连接，无法获取日志列表，请确认飞行器在舱内，通过远程调试将飞行器开机后重试",
    "324016": "机场存储空间不足，日志压缩失败，请清理机场存储空间或稍后重试",
    "324017": "日志压缩失败，无法获取所选飞行器日志，请刷新页面或重启机场后重试",
    "324018": "日志文件拉取失败，导致本次设备异常反馈上传失败，请稍后重试或重启机场后重试",
    "324019": "因机场网络异常，日志上传失败，请稍后重试。如果连续多次出现该问题，请联系代理商或大疆售后进行网络排障",
    "324030": "因机场网络异常、飞行器图传链路异常等原因，媒体文件暂时无法上传或文件已上传但云端读取失败",
    "325001": "云端下发给机场的命令不符合格式要求，机场无法执行",
    "325003": "设备端命令响应错误，请重试",
    "325004": "设备端命令请求已超时，请重试",
    "326103": "当前 eSIM 正在激活中，请稍后再试",
    "326104": "当前 eSIM 正在切换运营商中，请稍后再试",
    "326105": "DJI 增强图传模块正在切换模式中，请稍后再试",
    "326106": "DJI 增强图传模块异常，请重启设备后再试，如果仍报错请联系大疆售后",
    "326107": "请在设备管理 > 机场 > 设备运维中激活DJI增强图传模块的 eSIM 或插入 SIM 卡后再试",
    "327000": "参数设置失败，请稍后重试",
    "327001": "参数设置失败，请稍后重试",
    "327002": "获取控制权失败，请稍后重试",
    "327003": "获取控制权失败，请稍后重试",
    "327004": "画面拖动失败，请重试",
    "327005": "双击画面归中失败",
    "327006": "拍照失败",
    "327007": "开始录像失败",
    "327008": "停止录像失败",
    "327009": "切换相机模式失败",
    "327010": "ZOOM相机变焦失败",
    "327011": "IR相机变焦失败",
    "327012": "获取控制权失败，请稍后重试",
    "327013": "参数设置失败，请稍后重试",
    "327014": "云台已达限位",
    "327015": "直播启动失败，建议刷新直播或重新打开设备小窗",
    "327016": "失联动作设置失败，请重试",
    "327017": "指点飞行高度设置失败，请重试",
    "327018": "指点飞行模式切换失败，请重试",
    "327019": "当前状态无法看向标注点",
    "327020": "全景拍照停止命令超时",
    "327050": "当前设备状态不支持播放音频",
    "327051": "下载音频文件失败",
    "327052": "喊话器处理模式切换失败",
    "327053": "上传音频文件失败",
    "327054": "播放音频失败",
    "327055": "设置工作模式失败",
    "327056": "上传文本失败",
    "327057": "停止播放失败",
    "327058": "设置播放模式失败",
    "327059": "设置音量失败",
    "327060": "设置控件值失败",
    "327061": "发送文本值失败",
    "327062": "切换系统语言失败",
    "327063": "获取设备功能列表失败",
    "327064": "获取设备配置文件失败",
    "327065": "获取设备图片文件失败",
    "327066": "设备文件压缩失败",
    "327067": "设备文件上传失败",
    "327068": "上传音频文件失败，md5校验失败",
    "327069": "上传音频文件失败",
    "327070": "上传音频文件失败，异常终止",
    "327071": "上传TTS文本失败，md5校验失败",
    "327072": "上传TTS文本失败",
    "327073": "上传TTS文本失败，异常终止",
    "327074": "喊话器重播失败",
    "327075": "喊话器编码失败",
    "327201": "全景拍照失败",
    "327202": "全景拍摄终止",
    "327203": "当前设备不支持全景拍照",
    "327204": "系统繁忙，无法全景拍照",
    "327205": "请求失败，无法全景拍照",
    "327206": "飞机未起飞，无法开始全景拍摄",
    "327207": "控制权获取失败，全景拍摄终止",
    "327208": "未知相机错误，无法开始全景拍摄",
    "327209": "相机超时，全景拍摄终止",
    "327210": "无法全景拍照",
    "327211": "存储空间不足，全景拍摄终止",
    "327212": "飞机运动中，无法开始全景拍摄",
    "327213": "云台运动中，无法开始全景拍摄",
    "327214": "用户操作摇杆，全景拍摄终止",
    "327215": "碰到限飞区，全景拍摄终止",
    "327216": "触发距离限制，全景拍摄终止",
    "327217": "云台受阻，全景拍摄终止",
    "327218": "拍照失败，全景拍摄终止",
    "327219": "全景图片拼接失败",
    "327220": "加载标定参数失败，全景拍摄终止",
    "327221": "调整相机参数失败，全景拍摄终止",
    "327500": "飞行器镜头除雾失败，请稍后重试",
    "328051": "飞机未完成实名登记，请连接遥控器，按照指引完成、实名登记后飞行",
    "328052": "飞机实名登记状态已注销，请连接遥控器，按照指引完成实名登记后飞行",
    "328248": "飞行器起飞失败，请稍后重试，如果仍报错请联系大疆售后。",
    "336000": "指点飞行命令发送失败，请重试",
    "336001": "飞行器数据异常，无法响应指令",
    "336002": "飞行器GPS信号差",
    "336003": "飞行器定位失效，无法响应指令",
    "336004": "指点飞行自主规划失败",
    "336005": "飞行器返航点未更新",
    "336006": "飞行器已失联，已退出指点飞行",
    "336017": "飞行器电量不足以完成当前任务",
    "336018": "已切换飞行器规划模式",
    "336019": "指点飞行因限高自动调整飞行高度",
    "336513": "目标点在禁飞区内",
    "336514": "目标点超出飞行器限远",
    "336515": "目标点在禁飞区内",
    "336516": "目标点超出飞行器限高",
    "336517": "目标点超出飞行器限低",
    "337025": "飞行器无法起飞",
    "337026": "目标点异常，请重试",
    "337027": "飞行器速度设置异常，请重试",
    "337028": "飞行器版本异常，请检查飞行器版本",
    "337029": "飞行器无法响应当前任务，请稍后重试",
    "337030": "指令飞行安全离场高过低",
    "337537": "已触碰禁飞区",
    "337538": "已触碰飞行器限远",
    "337539": "已触碰禁飞区",
    "337540": "已触碰飞行器限高或限高区",
    "337541": "已触碰飞行器限低",
    "337542": "飞行器起飞失败，请重试",
    "337543": "目标点可能在障碍物内，请检查周边环境",
    "337544": "检测到障碍物，请检查周边环境",
    "337545": "飞行器规划异常，请重试",
    "337546": "已触碰自定义飞行区边界",
    "338001": "飞行器通信异常，无法执行飞行任务，请重启飞行器与机场后重试",
    "338002": "飞行器通信异常，无法执行飞行任务，请重启飞行器与机场后重试",
    "338003": "飞行器通信异常，无法执行飞行任务，请重启飞行器与机场后重试",
    "338004": "飞行器通信异常，无法执行飞行任务，请重启飞行器与机场后重试",
    "338005": "起飞机场与降落机场部署距离超出限制，无法执行飞行任务，请选择两个部署距离不超过 15km 的机场执行任务",
    "338006": "无法执行飞行任务，请检查降落机场是否已申请解禁证书、是否位于自定义禁飞区或是否位于自定义飞行区外",
    "338007": "目标降落机场部署突破限高区限高，无法执行任务，请申请解禁证书后再试",
    "338008": "目标降落机场部署突破飞行器设置的限高，无法执行任务，请调整限高后重试",
    "338009": "飞行器 GPS 定位信号差，无法执行任务，请重启飞行器后重试",
    "338010": "飞行器定位失效，无法执行任务，请重启飞行器后重试",
    "338017": "飞行器数据更新失败，无法执行任务，请重启飞行器后重试",
    "338018": "飞行器数据更新失败，无法执行任务，请重启飞行器后重试",
    "338019": "飞行器到目标机场的返航路线正在规划中，无法执行任务，请重启飞行器后重试",
    "338020": "飞行器无法根据规划的路径到达目标降落机场，无法执行任务，请重新选择降落机场后再试",
    "338021": "飞行器当前电量不足以到达目标降落机场，无法执行任务，请给飞行器充电后重试",
    "338049": "响应遥控器杆量，已退出指点飞行",
    "338050": "响应终止指令，已退出指点飞行",
    "338051": "飞行器低电量返航，已退出指点飞行",
    "338052": "飞行器低电量降落，已退出指点飞行",
    "338053": "附近有载人机，已退出指点飞行",
    "338054": "响应其他高优先级任务，已退出指点飞行",
    "338255": "飞行器通信异常，无法执行飞行任务，请重启飞行器与机场后重试",
    "386535": "航线执行异常，请稍后重试或重启机场后重试",
    "513001": "直播失败，飞行器不存在或飞行器类型错误",
    "513002": "直播失败，相机不存在或相机类型错误",
    "513003": "相机已经在直播中，请勿重复开启直播",
    "513005": "直播失败，直播参数（清晰度）设置错误",
    "513006": "操作失败，相机未开启直播",
    "513008": "直播失败，设备端图传数据异常",
    "513010": "直播失败，设备无法联网",
    "513011": "操作失败，设备未开启直播",
    "513012": "操作失败，设备已在直播中，不支持切换镜头",
    "513013": "直播失败，直播使用的视频传输协议不支持",
    "513014": "直播失败，直播参数错误或者不完整",
    "513015": "直播异常，网络卡顿, 请刷新后重试",
    "513016": "直播异常，视频解码失败",
    "513017": "直播已暂停，请等待当前飞行器媒体文件下载完成后再试",
    "513099": "直播失败，请稍后重试",
    "514100": "机场运行异常，请重启机场后重试",
    "514101": "推杆闭合失败，请检查停机坪上是否存在异物，飞行器方向是否放反，或重启机场后重试",
    "514102": "推杆展开失败，请检查停机坪上是否存在异物，或重启机场后重试",
    "514103": "飞行器电量低于30%，无法执行飞行任务，请充电后重试（建议电量≥50%）",
    "514104": "飞行器电池开始充电失败，请重启机场后重试",
    "514105": "飞行器电池停止充电失败，请重启机场后重试",
    "514106": "飞行器电源控制异常，请重启机场后重试",
    "514107": "舱盖开启失败，请检查舱盖周围是否存在异物，或重启机场后重试",
    "514108": "舱盖关闭失败，请检查舱盖周围是否存在异物，或重启机场后重试",
    "514109": "飞行器开机失败，请检查飞行器是否在舱和飞机电量是否正常，或重启机场后重试",
    "514110": "飞行器关机失败，请重启机场后重试",
    "514111": "飞行器慢转收桨控制异常，请重启机场后重试",
    "514112": "飞行器慢转收桨控制异常，请重启机场后重试",
    "514113": "机场推杆与飞行器无法连接，请检查飞行器是否在舱内，推杆闭合时是否被卡住，充电连接器是否脏污或损坏",
    "514114": "获取飞行器电源状态失败，请重启机场后重试",
    "514116": "无法执行当前操作，机场正在执行其他控制指令，请稍后重试",
    "514117": "舱盖开启或关闭未到位，请重启机场后重试",
    "514118": "推杆展开或闭合未到位，请重启机场后重试",
    "514120": "机场与飞行器断连，请重启机场后重试或重新对频",
    "514121": "机场急停按钮被按下，请释放急停按钮",
    "514122": "获取飞行器充电状态失败，请重启机场后重试",
    "514123": "飞行器电池电量过低无法开机",
    "514124": "获取飞行器电池信息失败，无法执行飞行任务，请重启机场后重试",
    "514125": "飞行器电池电量已接近满电状态，无法开始充电，请使用至95%以下再进行充电",
    "514134": "雨量过大，机场无法执行飞行任务，请稍后重试",
    "514135": "风速大于12m/s（26 mph），机场无法执行飞行任务，请稍后重试",
    "514136": "机场供电断开，机场无法执行飞行任务，请恢复机场供电后重试",
    "514137": "环境温度过低于-20℃ (-4°F)，机场无法执行飞行任务，请稍后重试",
    "514138": "飞行器电池正在保养中，机场无法执行飞行任务，请等待保养结束后重试",
    "514139": "飞行器电池无法执行保养指令，飞行器电池无需保养",
    "514140": "飞行器电池无法执行保养指令，飞行器电池无需保养",
    "514141": "机场系统运行异常，请重启机场后重试",
    "514142": "飞行器起飞前，机场推杆与飞行器无法连接，请检查飞行器是否在舱内，推杆闭合时是否被卡住，充电连接器是否脏污或损坏",
    "514143": "推杆未闭合或闭合不到位，请稍后重试或重启机场后重试",
    "514144": "舱盖未关闭或关闭不到位，请稍后重试或重启机场后重试",
    "514145": "机场处于现场调试中，无法执行当前操作或执行飞行任务，请断开遥控器和机场的数据线连接后重试",
    "514146": "机场处于远程调试中，无法执行飞行任务，请退出远程调试后重试",
    "514147": "设备升级中，无法进行远程调试或执行飞行任务，请等待升级完成后重试",
    "514148": "机场已经在作业中，无法进行远程调试或再次执行飞行任务，请等待当前任务执行完成后重试",
    "514149": "机场系统运行异常，无法执行飞行任务，请重启机场后重试",
    "514150": "设备重启中，无法执行飞行任务，请等待重启完成后重试",
    "514151": "设备升级中，无法执行设备重启指令，请等待升级完成后重试",
    "514153": "机场已退出远程调试模式，无法执行当前操作",
    "514154": "获取内循环出风口温度失败，请稍后再试",
    "514157": "执行开机失败，无线充电线圈业务繁忙，请重启机场后再试复",
    "514158": "无法起飞，机场 RTK 业务异常，请重启机场后再试",
    "514159": "任务失败，降落机场检测到飞行器，请确保降落机场没有飞行器后再试",
    "514170": "机场系统初始化中，无法执行当前操作或指令，请等待机场系统初始化完成后重试",
    "514171": "云端下发给机场的命令不符合格式要求，机场无法执行",
    "514172": "飞行器无法关机，蓝牙连接状态为未连接，请尝试重启飞行器和机场，或去现场重新对频飞行器与机场后再试",
    "514173": "由于天气原因（环境温度低于5度并且降雨大于等于中雨），可能导致桨叶结冰影响作业安全，暂无法执行任务",
    "514180": "停止空调制冷或停止空调制热失败，请稍后重试",
    "514181": "开启空调制冷失败，请稍后重试",
    "514182": "开启空调制热失败，请稍后重试",
    "514183": "开启空调除湿失败，请稍后重试",
    "514184": "当前温度低于 0 ℃（32°F），无法开启空调制冷",
    "514185": "当前温度高于 45 ℃（115°F），无法开启空调制热",
    "514300": "网关异常",
    "514301": "请求超时，连接断开",
    "514302": "网络证书异常，连接失败",
    "514303": "网络异常，连接断开",
    "514304": "机场请求被拒，连接失败",
    "321001": "	missionid不存在",
    "321002": "航线信息错误",
    "321003": "要执行的航线文件版本不匹配",
    "321006": "无效的命令",
    "321261": "飞行任务冲突，无法获取飞机控制权",
    "321262": "该状态下无法恢复航线",
    "321518":"RTK信号差",
    "321521":"超过机场限飞区限高",
    "321522":"请求自动起飞失败",
    "322026":"航点动作执行失败",
    "322281":"用户退出了航线"
}