import axios from "axios"
import store from '@/store/index';
import router from '@/router/index';
function createAxiosInstance(baseURL){
    let request=axios.create({
        baseURL: baseURL,
        timeout:5000
    })
    request.interceptors.request.use((config)=>{
        if(store.getters.getToken){
            config.headers.token=store.getters.getToken;
        }
        config.headers["Content-Type"]= 'application/json';
        return config
    })
    
    request.interceptors.response.use((response)=>{
        if(response.data.Status==-100){
            router.push('/login')
        }
        return response.data;
        
    },(error)=>{
        let message='';
        //http状态码
        let status =error.request.status;
        switch(status){
            case 401:
                message="TOKEN过期"
                break;
            case 403:
                message="无权访问"
                break;
            case 404:
                message="请求地址错误"
                break;
            case 500:
                message="服务器出现问题"
                break;
            default:
                message="网络出现问题"
                break;
        }
        // this.$message('error',message);
        if(baseURL=='https://fly-api.heteen.com'){
            return error.response?.data
        }else{
            return Promise.reject(error.response?.data);
        }
    })
    return request
}

const request = createAxiosInstance("https://fly-api.cnanydoor.com");
const requestwrj = createAxiosInstance("https://wrjapi.heteen.com");
const requestfly = createAxiosInstance("https://fly-api.heteen.com");
export {request,requestwrj,requestfly};