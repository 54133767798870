import { errorCode } from "@/utils/errorCode";
export default{
    data: function () {
        return {
            Cesium: {
                Ion: {
                    defaultAccessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZjJkNzE3ZS03MGU5LTRlNTUtOTJjNi05MjMxMDg1OWEyZGYiLCJpZCI6MTc0Nzc5LCJpYXQiOjE3MDE4NDc2NDB9.J555xbL8T6nnws-gk1J0wOzw2UyMlinGXhkPDDG1KZY'
                }
            },
        };
    },
    methods: {
        getReason(Code){
            return errorCode[Code];
        },
        replaceAll(str, what, newStr){
            while (str.indexOf(what) > -1) {
                str = str.replace(what, newStr);
            }
            return str;
        },
        pageBack(){
            history.back();
        },
        getQueryVariable(variable){
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i=0;i<vars.length;i++) {
                var pair = vars[i].split("=");
                if(pair[0] == variable){return pair[1];}
            }
            return(false);
        },
        formatDate(date, type) {
            var month = date.getMonth() + 1;
            var day = date.getDate();
            var hour = date.getHours();
            var minutes = date.getMinutes();

            month = month < 10 ? ("0" + month) : month;
            day = day < 10 ? ("0" + day) : day;
            hour = hour < 10 ? ("0" + hour) : hour;
            minutes = minutes < 10 ? ("0" + minutes) : minutes;

            if (type == 1) {
                return date.getFullYear()+`-` + month + `-` + day;
            }
            else if (type == 2) {
                return date.getFullYear()+`-` + month + `-` + day + ` ` + hour + `:` + minutes + `:00`;
            } else if (type == 3) {
                return date.getFullYear()+`-` + month;
            }
        },
        unicodeToChar(str) {
            //方案一
            return eval("'" + str + "'");
        },
        charToUnicode(str) {
            let temp;
            let i = 0;
            let r = '';

            for (let val of str) {
                temp = val.codePointAt(0).toString(16);

                while (temp.length < 4)
                    temp = '0' + temp;

                r += '\\u' + temp;
            };

            return r;
        },
        checkPwd(str) {
            var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}');
            return pwdRegex.test(str);
        },
        checkPhone(str) {
            if (!(/^1[3-9][0-9]\d{8}$/.test(str))) {
                return false;
            } else
                return true;
        },
        calculateNumOfDivsInContainer(containerId, divWidth, spacing) {//计算容器宽度
            const container = document.getElementById(containerId);
            if (container) {
                const containerWidth = container.offsetWidth;

                // 计算可用的宽度
                const availableWidth = containerWidth - (2 * spacing); // 假设两边各有 spacing 的间距

                // 计算可以放下的 div 个数
                const numOfDivs = Math.floor(availableWidth / (divWidth + spacing));
                console.log(numOfDivs);
                return numOfDivs;
            }
        }

    },
};