import Vue from 'vue'
import Vuex from 'vuex'
import { GET_MENU, GET_PLATFORM, GET_TOKEN} from '@/utils/token'
import { Menu } from 'element-ui'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: GET_TOKEN(),
    platForm:GET_PLATFORM(),
    menu:GET_MENU(),
  },
  getters: {
    getToken: state => state.token,
    getplatForm:state=>state.platForm,
    getMenu:state=>state.menu,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setplatForm(state,platForm){
      state.platForm = platForm;
    },
    setMenu(state,menu){
      state.menu = menu;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    setplatForm({ commit }, platForm) {
      commit('setplatForm', platForm);
    },
    setMenu({ commit }, menu) {
      commit('setMenu', menu);
    }
  },
  modules: {
  }
})
