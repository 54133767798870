import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
Vue.use(VueRouter)
export const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '@/views/index.vue'),
  },
  {
    path: '/login',
    name: 'userLogin',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/share/shareVideo',
    name: "分享直播",
    component: () => import('@/views/share/shareVideo.vue'),
  },
  {
    path: '/ceshi',
    name: "测试",
    component: () => import('@/views/share/demo1.vue'),
  },
]
export const asnycRoute=[
  {
    path: '/task/map',
    name: "航线库",
    component: () => import('@/views/task/map.vue'),
    meta:{
      breadcrumb: ['工作台','飞行任务','航线库'],
      title:"航线库",
    }
  },
  {
    path: '/task/addPlan',
    name: "添加任务",
    component: () => import('@/views/task/addPlan.vue'),
    meta:{
      breadcrumb: ['工作台','飞行任务','添加任务'],
      title:"添加任务",
    }
  },
  {
    path: '/task/taskLoop',
    name: "循环任务",
    component: () => import('@/views/task/taskLoop.vue'),
    meta:{
      breadcrumb: ['工作台','飞行任务','循环任务'],
      title:"循环任务",
    }
  },
  {
    path: '/task/flightTask',
    name: "待执行任务",
    component: () => import('@/views/task/flightTask.vue'),
    meta:{
      breadcrumb: ['工作台','飞行任务','待执行任务'],
      title:"待执行任务",
    }
  },
  {
    path: '/task/plan',
    name: "飞行记录",
    component: () => import('@/views/task/plan.vue'),
    meta:{
      breadcrumb: ['工作台','飞行任务','飞行记录'],
      title:"飞行记录",
    }
  },
  {
    path: '/control/myDevice',
    name: "我的设备",
    component: () => import('@/views/control/myDevice.vue'),
    meta:{
      breadcrumb: ['工作台','设备中心','我的设备'],
      title:"我的设备",
    }
  },
  {
    path: '/control/remote',
    name: "我的设备",
    component: () => import('@/views/control/remote.vue'),
    meta:{
      breadcrumb: ['工作台','设备中心','我的设备','远程操控'],
      title:"远程操控",
    }
  },
  {
    path: '/control/deviceAlarm',
    name: "告警记录",
    component: () => import('@/views/control/deviceAlarm.vue'),
    meta:{
      breadcrumb: ['工作台','设备中心','告警记录'],
      title:"告警记录",
    }
  },
  {
    path: '/media/AIRecord',
    name: "识别记录",
    component: () => import('@/views/media/AIRecord.vue'),
    meta:{
      breadcrumb: ['工作台','拍摄记录','识别记录'],
      title:"识别记录",
    }
  },
  {
    path: '/media/flightReplay',
    name: "飞行回放",
    component: () => import('@/views/media/fightReplay.vue'),
    meta:{
      breadcrumb: ['工作台','拍摄记录','飞行回放'],
      title:"飞行回放",
    }
  },
  {
    path: '/user/userList',
    name: "账号管理",
    component: () => import('@/views/user/userList.vue'),
    meta:{
      breadcrumb: ['工作台','系统管理','账号管理'],
      title:"账号管理",
    }
  },
  {
    path: '/user/userRole',
    name: "角色管理",
    component: () => import('@/views/user/userRole.vue'),
    meta:{
      breadcrumb: ['工作台','系统管理','角色管理'],
      title:"角色管理",
    }
  },
  {
    path: '/user/actionLog',
    name: "操作日志",
    component: () => import('@/views/user/actionLog.vue'),
    meta:{
      breadcrumb: ['工作台','系统管理','操作日志'],
      title:"操作日志",
    }
  },
  
]
export const asyncfixedRoute=[
  {
    path: '/home/main',
    name: '工作台',
    component: () => import(/* webpackChunkName: "about" */ '@/views/main.vue'),
    meta:{
      breadcrumb: ['工作台'],
      title:"工作台",
    }
  },
  {
    path: '/user/personalCenter',
    name: "个人中心",
    component: () => import('@/views/user/personalCenter.vue'),
    meta:{
      breadcrumb: ['工作台','个人中心'],
      title:"个人中心",
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/noPower.vue'),
    name: 'Any',//命名路由
  },
]
export const asyncuserRoute=[
  {
    path: '/device/deviceList',
    name: "设备管理",
    component: () => import('@/views/device/deviceList.vue'),
    meta:{
      breadcrumb: ['工作台','设备管理'],
    }
  },
  {
    path: '/cus/cusList',
    name: "客户管理",
    component: () => import('@/views/cus/cusList.vue'),
    breadcrumb: ['工作台','客户管理','查看设备'],
  },
  {
    path: '/cus/deviceList',
    name: "客户管理",
    component: () => import('@/views/cus/cusDevice.vue'),
    breadcrumb: ['工作台','客户管理'],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/noPower.vue'),
    name: 'Any',//命名路由
  },
]
function addNewRoute(route) {
  router.addRoute(route);
};
function getAsyncRoutes(menus, arr = []){
  menus?.map(menu => {
      // 检查当前菜单项是否在异步路由中
      let falg=asnycRoute.some(route => {
          return menu.name === route.name
      });
      if(falg){
        arr.push(menu)
      }
      // 如果菜单项有子菜单，递归检查子菜单
      if (menu.children && menu.children.length > 0) {
          getAsyncRoutes(menu.children, arr);
      }
  });
  return arr;
}
function getRouter(arr){
  arr?.map(menu => {
    // 检查当前菜单项是否在异步路由中
    asnycRoute.some(route => {
      if(menu.name===route.name){
        addNewRoute(route)
      }
    })
});
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes,
})
if(store.getters.getplatForm==2){
  let arr=getAsyncRoutes(JSON.parse(store.getters.getMenu));
  getRouter(arr);
  asyncfixedRoute.forEach(item=>{
    addNewRoute(item)
  })
  // addNewRoute(asnycRoute[0])
  // addNewRoute(asnycRoute[asnycRoute.length-1])
}else if(store.getters.getplatForm==1){
  asyncuserRoute.forEach(item=>{
    addNewRoute(item)
 })
}
export default router
