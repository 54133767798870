export const SET_TOKEN=(token)=>{
    localStorage.setItem("Token",token)
}
export const GET_TOKEN=()=>{
    return localStorage.getItem("Token")
}
export const REMOVE_TOKEN=()=>{
    return localStorage.removeItem("Token")
}
export const SET_platForm=(platForm)=>{
    localStorage.setItem("platForm",JSON.stringify(platForm))
}
export const GET_PLATFORM=()=>{
    return localStorage.getItem("platForm")
}
export const REMOVE_PLATFORM=()=>{
    return localStorage.removeItem("platForm")
}
export const SET_MENU=(Menu)=>{
    localStorage.setItem("Menu",JSON.stringify(Menu))
}
export const GET_MENU=()=>{
    return localStorage.getItem("Menu")
}
export const REMOVE_MENU=()=>{
    return localStorage.removeItem("Menu")
}