import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/Content/ElementUI/element-ui.css';
import store from './store'
import '@/utils/json'
import VueResource from 'vue-resource';
import Myxin from '@/minxis/index';
import 'three';
import 'photo-sphere-viewer';
import '@/styles/index.scss';
import './permisstion.js';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueResource);
Vue.use(Myxin);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
